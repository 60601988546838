.wrapper {
	grid-template-columns: 100%;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"header"
		"imprint"
		"footer";
}

section.imprint {
	width: 100%;
	grid-area: imprint;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 16px;
	padding-left: 64px;
	padding-right: 256px;
	animation: fadein 1.15s;
	/* align-items: flex-end; */
	z-index: 2;
}

h2 {
	max-width: 500px;
}

.links-wrapper {
	margin-top: 32px;
}

.link-container {
	padding: 16px 0;
	display: flex;
	flex-direction: column;
	text-align: left;
	font-size: 14px;
}

.link-headline {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}

.link-description {
	padding-left: 22px;
	max-width: 600px;
	line-height: 150%;
}

.link-external {
	font-size: 16px;
	text-decoration: underline;
	font-weight: bold;
	display: flex;
	align-items: center;
}

.icon-external-link {
	margin-right: 8px;
}

/* p {
	line-height: 20px;
} */
/* 
br {
	display: block;
	margin: 10px 0;
} */

/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
	section.imprint {
		padding-left: 32px;
		padding-right: 128px;
	}
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	section.imprint {
		padding: 0 32px;
		padding-top: 64px;
		justify-content: center;
		text-align: center;
		hyphens: auto;
	}
	p {
		margin-top: 8px;
	}
	section.imprint h1 {
		margin-top: 16px;
	}
	.link-container {
		text-align: center;
		align-items: center;
	}
	.link-description {
		/* padding: 0; */
	}
}

/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
	section.imprint {
		padding-top: 48px;
	}
	.link-container {
		text-align: center;
		align-items: center;
		font-size: 12px;
	}
	.link-external {
		font-size: 14px;
		text-decoration: underline;
		font-weight: bold;
		display: flex;
		align-items: center;
	}
}
