/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
	section.picture img {
		left: -190px;
	}
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
	section.picture img {
		left: -350px;
	}
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	section.picture img {
		left: 0;
	}
}
/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
}
