.wrapper {
	grid-template-columns: 47% 53%;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"header header"
		"txt picture"
		"footer footer";
}

section.text li {
	list-style: square;
	margin-left: 32px;
}

/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
	.wrapper {
		grid-template-columns: 58% 42%;
	}
	section.picture img {
		left: -120px;
	}
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	.wrapper {
		grid-template-columns: 100%;
		grid-template-rows: 80px auto auto auto;
		grid-template-areas:
			"header"
			"picture"
			"txt"
			"footer";
	}

	section.picture img {
		left: 0;
	}

	section.text li {
		list-style: square;
		text-align: left;
		margin-left: 64px;
		margin-right: 16px;
	}
}
/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
	section.text li {
		list-style: square;
		text-align: left;
		margin-left: 16px;
		margin-right: 0;
	}

	.wrapper {
		grid-template-rows: 60px auto auto auto;
	}
}
