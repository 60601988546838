.wrapper {
	grid-template-columns: 100%;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"header"
		"imprint"
		"footer";
}

section.imprint {
	width: 100%;
	grid-area: imprint;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 64px;
	padding-right: 256px;
	animation: fadein 1.15s;
	/* align-items: flex-end; */
	z-index: 2;
}

/* p {
	line-height: 20px;
} */

br {
	display: block;
	margin: 10px 0;
}

/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
	section.imprint {
		padding-left: 32px;
		padding-right: 128px;
	}
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	section.imprint {
		padding: 0 32px;
		padding-top: 64px;
		justify-content: center;
		text-align: center;
		hyphens: auto;
	}
	p {
		margin-top: 8px;
	}
	section.imprint h1 {
		margin-top: 16px;
	}
}

/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
	section.imprint {
		padding-top: 48px;
	}
}
