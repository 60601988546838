.wrapper {
	grid-template-columns: 100%;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"header"
		"accordion"
		"footer";
}

section.faqs {
	/* width: 100%; */
	/* grid-area: accordion; */
	/* position: relative; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-left: 64px;
	padding-right: 256px;
	padding-top: 16px;
	padding-bottom: 32px;
	/* align-items: flex-end; */
	animation: fadein 1.15s;
	/* z-index: 2; */
}

/* ##################
### ACCORDION
################## */

.accordion {
	background-color: white;
	color: #444;
	cursor: pointer;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	transition: 0.4s;
	font-family: Merriweather;
	border: 1px solid rgb(235, 235, 235);
	border-radius: 8px;
}

.active {
	background-color: rgb(245, 245, 245);
	color: var(--default-grey);
	border: 1px solid rgb(219, 219, 219);
}

.accordion:after {
	content: "\002B";
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
}

.active:after {
	content: "\2212";
}

.panel {
	padding: 0 18px;
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
	section.faqs {
		padding-left: 32px;
		padding-right: 32px;
	}
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	section.faqs {
		padding-top: 80px;
		align-items: center;
	}
}
/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
	section.faqs {
		padding-top: 56px;
	}
	.accordion {
		font-size: 12px;
	}
}
