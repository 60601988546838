.wrapper {
	grid-template-columns: 47% 53%;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"header header"
		"txt picture"
		"cards cards"
		"footer footer";
}

.gradient {
	grid-column: 1/3;
	grid-row: 1/3;
	background-repeat: no-repeat;
	background: linear-gradient(110deg, rgb(248, 248, 248) 40%, rgb(235, 235, 235) 60%);
}

section.picture.index {
	justify-content: flex-end;
	align-items: flex-end;
	padding-top: 0px;
	margin-top: 16px;
}

/* section.picture img { */
/* margin-top: 16px; */
/* object-fit: cover;
} */

.container-headlines {
	padding-left: 64px;
}

section.cards {
	width: 1280px;
	grid-area: cards;
	/* padding: 0 64px 0 64px; */
	background-color: white;
	border-top: 1px solid rgba(0, 0, 0, 0.075);
	/* border-bottom: 1px solid #bfbfbf; */
	display: flex;
	/* margin-top: 16px; */
	padding-top: 16px;
	padding-bottom: 32px;
	animation: fadein 1s;
	flex-direction: column;
}

.your-class {
	/* min-height: 300px; */
	/* border: 1px solid blue; */
}

.my-prev {
	/* position: absolute; */
	display: flex;
	justify-content: end;
	align-items: center;
	padding-right: 80px;
	border: 0;
	background-color: transparent;
	width: 200px;
	height: 70px;
	transition: all 0.3s;
}

.my-next {
	/* position: absolute; */
	display: flex;
	justify-content: start;
	align-items: center;
	padding-left: 80px;
	border: 0;
	background-color: transparent;
	width: 200px;
	height: 70px;
	transition: all 0.3s;
	/* border: 1px solid red; */
}

.my-prev:hover {
	cursor: pointer;
	transform: scale(1.15);
}

.my-next:hover {
	cursor: pointer;
	transform: scale(1.15);
}

/* .mask {
	mask-image: linear-gradient(to right, black 70%, transparent 100%);
	-webkit-mask-image: linear-gradient(to right, black 70%, transparent 100%);
} */

/* .mask {
	mask-image: linear-gradient(to right, transparent 1%, black 3%, black 90%, transparent 95%, transparent 100%);
	-webkit-mask-image: linear-gradient(to right, transparent 2%, black 5%, black 95%, transparent 98%);
} */

.wrapper-slider {
	width: 100%;
	position: relative;
	max-height: 420px;
	/* display: flex; */
}

.wrapper-slider-nav {
	position: absolute;
	top: 89%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* border: 1px solid red; */
}

.cards-outer {
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
}

.card-wrapper {
	display: flex;
	justify-content: start;
	align-items: center;
}

.cards-wrapper {
	width: 100%;
	height: auto;
	display: flex;
	/* justify-content: space-between; */
	margin-top: 16px;
	/* flex-wrap: wrap; */
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	transition: 0.2s;
	transform: scale(0.98);
	will-change: transform;
	user-select: none;
	cursor: pointer;
}

.hide-scroll {
	overflow-y: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar {
	/* WebKit */
	width: 0;
	height: 0;
}

.card-new {
	min-width: 200px;
	height: 200px;
	border: 1px solid red;
}

.card {
	width: 264px;
	/* height: 340px; */
	margin: 4px 4px;
	background: #ffffff;
	/* border: 1px solid #bfbfbf; */
	box-shadow: 6px 6px 29px -13px rgba(0, 0, 0, 0.25);
	border-radius: 18px;
	text-align: center;
	overflow: hidden;
	display: inline-block;
	grid-template-rows: 64px auto auto auto;
	transition: 0.2s;
}

/* .grow:hover > .btn.outline.card {
	background: var(--default-grey);
	color: white;
} */

.card h1 {
	text-decoration: none;
	font-family: Merriweather;
}

.card-a {
	text-decoration: none;
	transition: 0.2s;
}

.card-a:hover {
	text-decoration: none;
	transition: 0.2s;
}

.grow {
	/* transition: all 0.1s ease-in-out; */
}
.grow:hover {
	transform: scale(1.03, 1.03);
	cursor: pointer;
}
.card-header {
	flex-shrink: 0;
	height: 64px;
	background: #e5e5e5;
	font-family: Merriweather;
	font-size: 18px;
	font-weight: 300;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cardbody {
	padding: 8px 30px;
	text-align: center;
	height: 100%;
}

h1.cardheadline {
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	margin-top: 12px;
	padding: 0 48px;
	color: #464646;
	white-space: normal;
}

p.cardcopy {
	line-height: 22px;
	white-space: normal;
	margin: 0;
	padding: 0 28px;
	padding-top: 16px;
	/* padding-bottom: 32px; */
}

.btn.outline.card {
	display: flex;
	align-self: start;
	width: 90px;
	margin: 16px auto;
	box-shadow: none;
}

/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
	#photo-wackwitz {
		width: 778px;
		height: 680px;
	}
	#btn-open-calculator {
		margin-left: 16px;
	}
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
	.wrapper {
		grid-template-columns: 58% 42%;
	}

	#btn-open-calculator {
		margin-left: 16px;
	}

	#photo-wackwitz {
		width: 736px;
		height: 644px;
		right: -100px;
	}

	.container-headlines {
		padding-left: 32px;
	}

	.cards-wrapper {
		justify-content: flex-start;
	}

	section.cards {
		width: 960px;
	}

	.my-prev {
		left: -30px;
	}

	.my-next {
		right: -30px;
	}
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	.wrapper {
		grid-template-rows: 80px auto auto auto auto;
		grid-template-columns: 100%;
		grid-template-areas:
			"header"
			"picture"
			"txt"
			"cards"
			"footer";
	}

	#btn-open-calculator {
		margin-left: 0;
	}

	section.cards {
		padding: 16px 0;
		padding-bottom: 32px;
		align-items: center;
		width: 600px;
	}

	section.picture.index {
		padding-top: 16px;
		justify-content: center;
	}

	/* .cards-wrapper {
		justify-content: center;
	} */

	.cardcopy {
		-moz-hyphens: auto;
		-o-hyphens: auto;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
		text-overflow: ellipsis;
	}

	#photo-wackwitz {
		width: 542px;
		height: 474px;
		right: auto;
		position: relative;
	}

	.card-wrapper {
		justify-content: center;
		margin: 0 auto;
	}

	.my-prev {
		margin-right: 50px;
	}

	.my-next {
		margin-left: 50px;
	}
}
/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
	.wrapper {
		grid-template-rows: 60px auto auto auto;
	}

	.container-headlines {
		padding: 0;
	}

	/* .wrapper-slider-nav {
		display: none;
	} */

	.card {
		/* height: 320px; */
		grid-template-rows: 64px auto auto auto;
	}

	#photo-wackwitz {
		width: 288px;
		height: 252px;
	}

	section.cards {
		width: 100%;
	}
	.my-prev {
		margin-right: 30px;
	}

	.my-next {
		margin-left: 30px;
	}
}
