/* #################### */
/* HAMBURGER */
/* #################### */

/* @keyframes fadein {
	from {
		background: linear-gradient(110deg, rgba(108, 108, 108, 0) 20%, rgba(40, 40, 40, 0) 70%);
	}
	to {
		background: linear-gradient(110deg, rgba(108, 108, 108, 0.9) 20%, rgba(40, 40, 40, 0.9) 70%);
	}
} */

.wrapper-hamburger {
	position: absolute;
	display: none;
	width: 100%;
	margin: 0;
	z-index: 10;
	grid-template-columns: auto auto auto;
	grid-template-rows: 80px auto;
	grid-template-areas:
		"logo btn hamburger"
		"links links links";
	color: white;
	font-size: 14px;
	background: rgba(0, 0, 0, 0);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.container-logo-hamburger {
	grid-area: logo;
	display: flex;
	width: 100%;
	padding-left: 32px;
	align-items: center;
}

.container-logo-hamburger > img {
	width: 142px;
}

.container-icon-hamburger {
	grid-area: hamburger;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding-right: 32px;
	align-items: center;
}

.container-icon-hamburger img {
	width: 32px;
	cursor: pointer;
}

/* .container-icon-hamburger img:hover {
	filter: brightness(3);
} */

.container-btn-open-calculator-hamburger {
	grid-area: btn;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-right: 20px;
}

.container-nav-items-hamburger {
	width: 100%;
	display: none;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding-top: 16px;
	padding-right: 32px;
	text-align: right;
	grid-area: links;
	animation: fadein 1s;
}

.container-nav-items-hamburger ul {
	margin-bottom: 16px;
	line-height: 32px;
	width: 100%;
}

.container-nav-items-hamburger ul li {
	width: 300px;
}

.wrapper-hamburger a {
	color: white;
}

.wrapper-hamburger a:hover {
	color: var(--hover-dark-white);
}

.hamburger-category {
	/* text-decoration: underline; */
	text-transform: uppercase;
	font-weight: 700;
	width: 100%;
	margin-bottom: 8px;
}

.hamburger-social-media {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: 8px;
	margin-bottom: 32px;
	/* padding: 0 80px; */
}

.hamburger-social-media a {
	filter: brightness(10);
}

.hamburger-social-media a img {
	margin-left: 15px;
}

.hamburger-social-media a:hover {
	filter: brightness(3);
}

.btn.hamburger-menu-open {
	border: 2px solid white;
	color: white;
}

.btn.hamburger-menu-open:hover {
	border: 2px solid white;
	color: var(--default-grey);
	background-color: white;
}

/* ----------- 1280-WIDTH */
@media only screen and (min-width: 1280px) {
}

/* ----------- 960-WIDTH */
@media (max-width: 1279px) {
}

/* ----------- 600-WIDTH */
@media only screen and (max-width: 959px) {
	.wrapper-hamburger {
		display: grid;
	}
}
/* ----------- 320-WIDTH */
@media only screen and (max-width: 599px) {
	.wrapper-hamburger {
		grid-template-columns: auto auto;
		grid-template-rows: 60px auto auto;
		grid-template-areas:
			"logo hamburger"
			"btn btn"
			"links links";
	}

	.container-logo-hamburger {
		padding-left: 32px;
	}

	.container-icon-hamburger {
		/* padding-right: 16px; */
	}

	.container-nav-items-hamburger {
		display: none;
	}

	.container-btn-open-calculator-hamburger {
		display: none;
		margin-top: 8px;
		/* justify-content: start; */
		padding-left: 16px;
	}

	.container-nav-items-hamburger {
		padding-left: 16px;
	}
}
